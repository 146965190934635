<template>
  <div class="admin-menu-items AdminMenuPosts">
    <div class="admin-menu-items-content">
      <div
        :class="`admin-menu-item ${mkLink('adminStatus=new&type=post', 'on')}`"
        @click="goFilter({ adminStatus: 'new', type: 'post', view: _view })"
      >
        <span>{{ $locale["words"]["pending"] }}</span>
        <span class="itemBubble" v-if="postsByReview">{{ postsByReview }}</span>
      </div>
      <div
        :class="`admin-menu-item ${mkLink('adminStatus=approved&type=post', 'on')}`"
        @click="goFilter({ adminStatus: 'approved', type: 'post', view: _view })"
      >
        <span>{{ $locale["words"]["approved"] }}</span>
      </div>
      <div
        :class="`admin-menu-item ${mkLink('adminStatus=disapproved&type=post', 'on')}`"
        @click="goFilter({ adminStatus: 'disapproved', type: 'post', view: _view })"
      >
        <span>{{ $locale["words"]["disapproved"] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["_view"],
  computed: {
    postsByReview: function() {
      return this.$store.state.counter.postsByReview;
    },
  },
};
</script>
